.App-logo {
    pointer-events: none;
    height: 10rem;
}
.App-header {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.App-link {
    color: #61dafb;
}
